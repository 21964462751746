/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC350M
 * 화면 설명: TS캘린더
 * 작 성 일: 2022.06.30
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container :show-title="true" title="TS 캘린더" type="subpage" action-type="search" @action-search-click="fn_OpenMSPRC351P()">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-schedule tss">
        <mo-scheduler ref="loginScheduler" :events="tsPnstaList" displayPeriodUom="month" :displayPeriodCount="1"
          @period-changed="fn_PeriodChanged" @click-event="fn_SelectDate" @click-date="fn_SelectDate" :month-picker-props="monthPickerProps"
          :holidays="holidays" eventTop="30px" eventContentHeight="16px" schedule-detail>
          <!-- 년/월 클릭시 보이는 영역 -->
          <template #month-picker="{year, month}">
            <div>
              {{year}}년 {{month}}월
              <mo-icon xsmall>calendar</mo-icon>
            </div>
          </template>
        </mo-scheduler>
      </ur-box-container>
    </ur-box-container>

    <!-- Toast -->
    <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

  </ur-page-container>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'
import SearchDetail from '@/ui/rc/SearchDetail.vue'
import MSPRC351P from '@/ui/rc/MSPRC351P.vue' // TS 캘린더 상세조회 팝업
import MSPRC352P from '@/ui/rc/MSPRC352P.vue' // TS 캘린더 일정조회 팝업

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  name: 'MSPRC350M',
  screenId: 'MSPRC350M',
  mixins:[Screen],
  components: {
    SearchDetail,
    MSPRC351P,
    MSPRC352P
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data() {
    return {
      // 팝업 연결 변수
      popup351: {}, // MSPRC351P TS캘린더 상세조회조건
      popup352: {}, // MSPRC352P TS 캘린더 일정조회
      pOrgData350M: {}, // 최근 조직 조회 데이터

      // 휴일 조회용 Service IF VO
      lv_NowLoadingHoliday: false, // 휴일 조회중 여부
      holidayList: [],
      holidays: [],

      // 조회 변수
      userInfo: {}, // 사용자정보 obj
      tsPnstaList: [], // TS신청현황 리스트

      // 조직 조회 변수
      lv_SelectOrg: [], // 조직 컴포넌트 데이터
      hqOrgCd: '', // 본부조직코드
      dofOrgCd: '', // 지역단조직코드
      fofOrgCd: '', // 지점조직코드

      // 달력 변수
      startSchdDay: '', // 달력의 첫날
      endSchdDay: '', // 달력의 마지막날
      periodStart: '', // 해당 월의 첫날
      periodEnd: '', // 해당 월의 마지막날
      choiceYm: '', // 선택된 년월
      nextMonth: '', // 현재 월의 다음 달
      monthPickerProps: { // 선택할 수 있는 월 제한
        minDate: '',
        maxDate: ''
      },

      toastMsg: '', // toast 문구
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {},
  
  /** watch 정의 영역 */
  watch: {
    holidayList() {
      this.holidays.splice(0, this.holidays.length)
      for (let i = 0; i < this.holidayList.length; i++) {
        // 법정공휴일 : 1 , 윤년/음력 : 2
        let tempClass = this.holidayList[i].slrcYmYmd
        if (this.holidayList[i].holyCd === '1') {
          // 텍스트가 날짜와 겹치는 이슈로 label 제거
          // this.holidays.push({date: tempClass, label: this.holidayList[i].holyNm, holidayClasses: 'holiday-txt-color', dayNumberClasses: 'holiday-txt-color', holyCd: 1})
          this.holidays.push({date: tempClass, holidayClasses: 'holiday-txt-color', dayNumberClasses: 'holiday-txt-color', holyCd: 1})
        } else if (this.holidayList[i].holyCd === '2') {
          this.holidays.push({date: tempClass, holidayClasses: 'holiday-txt-color', dayNumberClasses: 'holiday-txt-color', holyCd: 2})
        }
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    const lv_Vm = this
    this.userInfo = this.getStore('userInfo').getters.getUserInfo

    // 바텀 메뉴 보임 처리
    lv_Vm.$BottomManager.fn_SetBottomVisible(true)
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPRC350M')
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_PeriodChanged
     * 설명       : 기간이 변경되었을 때 처리하는 Function
     *            : 기간 관련 변수
     *             displayFirstDate - 달력의 첫날
     *             displayLastDate - 달력의 마지막날
     *             periodStart - 해당 월의 첫날
     *             periodEnd - 해당 월의 마지막날
     *             nextMonth - 현재 월의 다음 달
     ******************************************************************************/
    fn_PeriodChanged (date) {
      this.startSchdDay = moment(date.displayFirstDate).format('YYYYMMDD')
      this.endSchdDay = moment(date.displayLastDate).format('YYYYMMDD')
      this.periodStart = moment(date.periodStart).format('YYYYMMDD')
      this.periodEnd = moment(date.periodEnd).format('YYYYMMDD')
      this.choiceYm = moment(date.periodStart).format('YYYYMM')
      this.nextMonth = moment(new Date()).add(1, 'months').format('YYYYMM')

      // 현재 달과 그 다음 달만을 조회하기 위한 로직
      // this.monthPickerProps.minDate = '2022-04-11' // test
      this.monthPickerProps.minDate = moment(new Date()).format('YYYY-MM-DD')
      this.monthPickerProps.maxDate = moment(new Date()).add(1,'month').format('YYYY-MM-DD')

      // 변경된 일정의 휴일 조회
      this.fn_SelectHolidayList()
    },

    /******************************************************************************
    * Function명 : fn_SelectHolidayList
    * 설명       : 휴일 목록을 조회한다.
    ******************************************************************************/
    fn_SelectHolidayList() {
      const lv_Vm = this

      if (lv_Vm.lv_NowLoadingHoliday) return

      this.lv_NowLoadingHoliday = true
      lv_Vm.holidayList = []

      let trnstId = 'txTSSAP03S2'
      let pParam = {
        inqrStrYmd: lv_Vm.startSchdDay,
        inqrEndYmd: lv_Vm.endSchdDay
      }

      lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
        if (!_.isEmpty(response.body)) {
          lv_Vm.iCYmdMngVO = response.body.iCYmdMngVO

          if (!_.isEmpty(lv_Vm.iCYmdMngVO)) {
            for (let j = 0; j < lv_Vm.iCYmdMngVO.length; j++) {
              let tmpObj = {}
              tmpObj.slrcYmYmd = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.iCYmdMngVO[j].slrcYmYmd)
              tmpObj.holyCd = lv_Vm.iCYmdMngVO[j].holyCd
              tmpObj.holyNm = lv_Vm.iCYmdMngVO[j].holyNm

              lv_Vm.holidayList.push(tmpObj)
            }
          }
        }

        lv_Vm.lv_NowLoadingHoliday = false
        // TS 신청 현황 조회
        lv_Vm.fn_SelectListPrdSchdl()
      }).catch(function (error) {
        window.vue.error(error)
      })
    },

    /******************************************************************************
     * Function명 : fn_SelectListPrdSchdl
     * 설명       : TS 신청 현황 조회
     ******************************************************************************/
    fn_SelectListPrdSchdl() {
      if (!_.isEmpty(this.pOrgData350M)) {
        if (this.pOrgData350M.hofOrg.key === '00000000' || this.pOrgData350M.dofOrg.key === '0') {
          return
        }
      }

      const lv_Vm = this
      const trnstId = 'txTSSRC60S1'
      const auth = 'S'

      // 초기화
      lv_Vm.tsPnstaList = []

      // 최초 조회시 userInfo에서 정보 가져옴
      if (!_.isEmpty(lv_Vm.pOrgData350M)) {
        lv_Vm.hqOrgCd = lv_Vm.pOrgData350M.hofOrg.key // 본부조직코드
        lv_Vm.dofOrgCd = lv_Vm.pOrgData350M.dofOrg.key // 지역단조직코드
        lv_Vm.fofOrgCd = lv_Vm.pOrgData350M.fofOrg.key // 지점조직코드
      } else {
        lv_Vm.hqOrgCd = this.userInfo.onpstHofOrgNo // 본부조직코드
        lv_Vm.dofOrgCd = this.userInfo.onpstDofNo // 지역단조직코드
        lv_Vm.fofOrgCd = this.userInfo.onpstFofOrgNo // 지점조직코드
      }

      let pParams = {
        hqOrgCd: lv_Vm.hqOrgCd,
        dofOrgCd: lv_Vm.dofOrgCd,
        fofOrgCd: lv_Vm.fofOrgCd,
        cnsltEno: '',
        tsAplYm: lv_Vm.periodStart.substring(0, 6) // TS신청년월
      }

      lv_Vm.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body)) {
            lv_Vm.tsPnstaList = []
            let responseList = response.body.tsPnstaList
            if (!_.isEmpty(responseList) && responseList.length > 0) {
              lv_Vm.tsPnstaList = []
              responseList.forEach(item => {
                lv_Vm.tsPnstaList.push({
                  startDate: moment(item.tsAplYmd).format('YYYY-MM-DD'),
                  endDate: moment(item.tsAplYmd).format('YYYY-MM-DD'),
                  title: `${item.tsAplNprsn}건`,
                  classes: 'blue-event'
                })
              })
            }
          } else {
            lv_Vm.tsPnstaList = []
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명: fn_OpenMSPRC351P
     * 설명: TS캘린더 상세조회조건 팝업 호출
     ******************************************************************************/
    fn_OpenMSPRC351P () {
      this.popup351 = this.$bottomModal.open(MSPRC351P, {
        properties: {
          pOrgData350M: this.pOrgData350M, // 최근 조직 조회 데이터
        },
        listeners: {
          onPopupSearch: (pData) => {
            this.$bottomModal.close(this.popup351)

            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData350M = pData.OrgData.pOrgData

            // TS 신청 현황 조회
            this.fn_SelectListPrdSchdl()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_SelectDate
     * 설명       : 일자선택 시 일정조회 팝업 호출
     ******************************************************************************/
    fn_SelectDate (date) {
      let paramDate = ''
      if (!this.$bizUtil.isEmpty(date.endDate)) { // @click-event
        paramDate = date.endDate
      } else { // @click-date
        paramDate = date
      }

      this.popup352 = this.$bottomModal.open(MSPRC352P, {
        properties: {
          pSlctDate: paramDate, // 선택한 날짜
          pOrgData350M: this.pOrgData350M // 조직정보
        },
        listeners: {
          // 취소 버튼
          onPopupCancel: () => {
            this.$bottomModal.close(this.popup352)
          },
          // TS취소 후 재조회
          onPopupClose352: () => {
            this.$bottomModal.close(this.popup352)

            this.toastMsg = '취소가 완료되었습니다.'
            this.$refs.snackbar.show({ text: this.toastMsg })

            // TS 신청 현황 조회
            this.fn_SelectListPrdSchdl()
          },
          // 결과입력 후 재조회
          onPopupClose353: () => {
            this.$bottomModal.close(this.popup352)

            this.toastMsg = '결과 전송이 완료되었습니다.'
            this.$refs.snackbar.show({ text: this.toastMsg })

            // TS 신청 현황 조회
            this.fn_SelectListPrdSchdl()
          }
        }
      })
    }
    
  }
}
</script>
