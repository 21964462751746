/*
 * 업무구분: 리크루팅
 * 화 면 명: MSPRC352P
 * 화면설명: TS캘린더 일정조회
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.30
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container :show-title="false" :title="titleDate + ' ' + titleDay" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">

        <!-- 오전/오후 -->
        <!-- <span class="name" v-if="item.am === 'am0'">오전</span>
        <span class="name" v-if="item.pm === 'pm0'">오후</span> -->

        <!-- 리스트 영역 -->
        <ur-box-container v-show="!isEmptyView" direction="column" alignV="start" componentid="ur_box_container_001" class="ns-radio-list2">
          <mo-list :list-data="tsPnstaDtlList">
            <template #list-item="{item}">
              <mo-list-item>
                <mo-radio v-model="radio" :value="item.name" @input="fn_CheckPreSelectedItem(item)" />
                <div class="list-item__contents" @click="fn_CheckPreSelectedItem(item)" >
                  <div class="list-item__contents__title">
                    <span class="name">{{ item.name }}<em>|</em> <span class="txtBlue">{{ item.time }}</span></span>
                    <mo-badge class="badge-sample-badge sm blue" text="TS완료" v-if="getTsCmpltRslt(item)">TS완료</mo-badge>
                  </div>
                  <div class="list-item__contents__info">
                    <span>{{ item.birth }}</span><em>|</em><span>추천FC<span>{{ item.fc }}</span></span>
                  </div>
                  <div class="list-item__contents__info">
                    <span>{{ item.agency }}</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>

        <!-- NoData 영역  -->
        <ur-box-container v-show="isEmptyView" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>일정이 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </ur-box-container>

      <!-- 버튼 영역 -->
      <ur-box-container v-if="tsPnstaDtlList.length > 0" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <!-- 지역단 스텝이면서 지점장 권한을 가지고 있을 때는 결과입력 버튼만 보이기 -->
        <div v-if="isFofAuth" class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Close">취소</mo-button>
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue"
            @click="fn_CheckTSResult" :disabled="this.disableRslt">결과입력</mo-button>
        </div>
        <div v-else class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Close">취소</mo-button>
          <!-- TODO!!! 권한에 따른 버튼 visible 처리 -->
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue"
            v-is-visible="{acl: ['D']}"
            @click="fn_ConfirmTsAply" :disabled="this.disableAply">TS일정 삭제</mo-button>
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue"
            v-is-visible="{acl: ['I']}"
            @click="fn_CheckTSResult" :disabled="this.disableRslt">TS결과 입력</mo-button>
        </div>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPRC353P from '@/ui/rc/MSPRC353P.vue' // TS 결과입력

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  name: 'MSPRC352P',
  screenId: 'MSPRC352P',
  mixins:[Screen],
  components: {
    MSPRC353P
  },
  props: {
    pSlctDate: Date, // 채널고객명
    pOrgData350M: Object // 조직정보    
  },

  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      isFofAuth: false, // 권한 체크
      tsAplYmd: '', // TS신청일자(YYYY-MM-DD)
      preTsAplYmd: '', // TS신청일자(YYYYMMDD)
      titleDate: '', // 타이틀 날짜
      titleDay: '', // 타이틀 요일
      choiceYmd: '', // 선택날짜(YYYY-MM-DD)
      choiceDate: '', // 선택날짜(YYYYMMDD)

      currentDate: moment(new Date()).format('YYYYMMDD'), // 현재날짜
      currentTime: moment(new Date()).format('HHmm'), // 현재시간
      checkMsg: '',
      cndtrInfo: {},
      userInfo: {}, // 사용자정보 obj

      popup353: {},      
      tsPnstaDtlList: [], // TS신청현황 상세 리스트
      selectItems: {}, // 선택값 저장
      selCndtrList: [], // 화면에 표시된 정보 목록
      disableAply: true,
      disableRslt: true,

      radio: '',
      isEmptyView: false // NoData
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    
  },

  /** watch 정의 영역 */
  watch: {
    titleDate (value) {
      this.selectItems = {}
      this.disableAply = true
      this.disableRslt = true
    },
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    let userRoleList = this.getStore('userInfo').getters.getUserInfo.userRoleList

    let lv_Vm = this
    if (userRoleList.includes('F102') && userRoleList.includes('F110')) { // 지역단 스텝이면서 지점장 권한을 가지고 있는 사람
      lv_Vm.isFofAuth = true
    }

    lv_Vm.disableAply = true
    lv_Vm.disableRslt = true
    lv_Vm.selectItems = {}

    // 최초 로딩 시 수행
    lv_Vm.fn_Init()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  async mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPRC352P')

    this.getStore('progress').dispatch('UPDATE', true) // 프로그래스바 표시 시작
    // TS 신청 현황 상세 조회
    await this.fn_SelectSchdlList()

    // TS 완료 표시
    if (!_.isEmpty(this.tsPnstaDtlList) && this.tsPnstaDtlList.length > 0) {
        this.selCndtrList = []
        const promises = this.tsPnstaDtlList.map(item => this.fn_SetSelCtvtPnsta(item.parentId))
        await Promise.all(promises)
    }
    this.getStore('progress').dispatch('UPDATE', false) // 프로그래스바 표시 해제
  },

  /** 설명 : Step-9 인스턴스가 Active (활성화) 된 후 호출 */
  activated () {
    this.disableAply = true
    this.disableRslt = true
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 최초 로딩 시 수행되는 함수
     *              prop 데이터 정제
     ******************************************************************************/
    fn_Init () {
      let v = this.pSlctDate // Mon Jan 00 2022 00:00:00 GMT+0900 (한국 표준시)

      this.tsAplYmd = moment(v).format('YYYY-MM-DD') // TS신청일자(YYYY-MM-DD)
      this.preTsAplYmd = moment(v).format('YYYYMMDD') // TS신청일자(YYYYMMDD)
      this.titleDate = (v.getMonth() + 1) + '월 ' + (v.getDate() < 10 ? '0' + v.getDate() : v.getDate()) + '일' // 타이틀 날짜
      this.titleDay = this.fn_ConvertDate(v.toString().substring(0, 3)) // 타이틀 요일
      this.choiceYmd = moment(v).format('YYYY-MM-DD') // 선택날짜(YYYY-MM-DD)
      this.choiceDate = moment(v).format('YYYYMMDD') // 선택날짜(YYYYMMDD)
    },

    /******************************************************************************
    * Function명 : fn_SelectSchdlList
    * 설명       : TS 신청 현황 상세 조회
    ******************************************************************************/
    async fn_SelectSchdlList() {
      const lv_Vm = this
      let trnstId = 'txTSSRC60S2'
      const auth = 'S'
      let i = 0
      let j = 0

      // 리스트 초기화
      lv_Vm.tsPnstaDtlList = []

      // 파라미터 세팅
      let pParam = {}
      
      if (!_.isEmpty(this.pOrgData350M)) {
        pParam = {
          hqOrgCd: lv_Vm.pOrgData350M.hofOrg.key, // 본부조직코드
          dofOrgCd: lv_Vm.pOrgData350M.dofOrg.key, // 지역단조직코드
          fofOrgCd: lv_Vm.pOrgData350M.fofOrg.key, // 지점조직코드
          tsAplYmd: lv_Vm.tsAplYmd.replace(/\-/g, '') // TS신청일자
        }
      } else {
        pParam = {
          hqOrgCd: this.userInfo.onpstHofOrgNo, // 본부조직코드
          dofOrgCd: this.userInfo.onpstDofNo, // 지역단조직코드
          fofOrgCd: this.userInfo.onpstFofOrgNo, // 지점조직코드
          tsAplYmd: lv_Vm.tsAplYmd.replace(/\-/g, '') // TS신청일자
        }
      }

      await lv_Vm.post(lv_Vm, pParam, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body)) {            
            let responseList = response.body.tsPnstaList            
            if (!_.isEmpty(responseList) && responseList.length > 0) {              
              lv_Vm.tsPnstaDtlList = []
              responseList.forEach((item) => {
                let tmpObj = {}

                // 오전
                if (lv_Vm.fn_ConvertAmPm(item.tsAplTm) === 'am') {
                  tmpObj.am = lv_Vm.fn_ConvertAmPm(item.tsAplTm) + i.toString()
                  i++
                }
                // 오후
                if (lv_Vm.fn_ConvertAmPm(item.tsAplTm) === 'pm') {
                  tmpObj.pm = lv_Vm.fn_ConvertAmPm(item.tsAplTm) + j.toString()
                  j++
                }
                tmpObj.name = item.cndtrNm // 이름
                tmpObj.time = lv_Vm.fn_ConvertTime(item.tsAplTm, item.tsAplMm) // 시간
                tmpObj.birth = moment(item.dob).format('YYYY-MM-DD') // 생일
                tmpObj.fc = item.recmFcNm // FC명
                tmpObj.agency = item.fofNm // 지점명
                tmpObj.parentId = item.parentId // 후보자ID
                tmpObj.tsAplYmd = item.tsAplYmd // TS신청날짜
                tmpObj.tsAplTm = item.tsAplTm // TS신청시간(1~24)
                tmpObj.tsAplMm = item.tsAplMm // TS신청분(30분단위)
                tmpObj.parentId = item.parentId // 후보자ID
                tmpObj.tsAplSc = item.tsAplSc // TS신청구분
                if (!_.isEmpty(lv_Vm.pOrgData350M)) {
                  tmpObj.hqOrgCd = lv_Vm.pOrgData350M.hofOrg.key // 본부조직코드
                  tmpObj.dofOrgCd = lv_Vm.pOrgData350M.dofOrg.key // 지역단조직코드
                } else {
                  tmpObj.hqOrgCd = lv_Vm.userInfo.onpstHofOrgNo // 본부조직코드
                  tmpObj.dofOrgCd = lv_Vm.userInfo.onpstDofNo // 지역단조직코드
                }
                tmpObj.fofOrgCd = item.fofCd // 지점조직코드
                tmpObj.tsEfYmd = item.tsEfYmd // TS 실시일자
                tmpObj.tsRsltYmd = item.tsRsltYmd // TS 결과일자
                tmpObj.choiceDate = lv_Vm.choiceDate
                
                lv_Vm.tsPnstaDtlList.push(tmpObj)
              })

              if (lv_Vm.tsPnstaDtlList.length === 0) {
                lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
              } else {                
                lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false               
              }                            
            } else {
              lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
              lv_Vm.tsPnstaDtlList = [] // 리스트 초기화
            }
          } else {
            lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_CheckSelectedItem
    * 설명       : 선택한 후보자의 TS 결과 입력 날짜를 가져온다
    ******************************************************************************/
    fn_CheckSelectedItem (item) {
      let lv_Vm = this
      let trnstId = 'txTSSRC40S1'
      let auth = 'S'

      // 버튼 초기화
      lv_Vm.disableAply = true
      lv_Vm.disableRslt = true

      // 선택값 저장
      lv_Vm.selectItems = item

      // 라디오 세팅
      lv_Vm.radio = item.name

      // TS 결과 입력날짜 조회
      let pParams = {}
      pParams.hqOrgCd = lv_Vm.selectItems.hqOrgCd // 본부조직코드
      pParams.dofOrgCd = lv_Vm.selectItems.dofOrgCd // 지역단조직코드
      pParams.fofOrgCd = lv_Vm.selectItems.fofOrgCd // 지점조직코드
      pParams.parentId = lv_Vm.selectItems.parentId
      pParams.cnsltEno = ''      

      lv_Vm.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body !== null && response.body !== '') {            
            let result = response.body.whlCndtrList
            if (result.length !== 0) {
              for (let i = 0; i < result.length; i++) {
                result.forEach((item) => {
                  lv_Vm.selectItems.tsAplYmd = item.tsAplYmd
                  lv_Vm.selectItems.tsEfYmd = item.tsEfYmd
                  lv_Vm.selectItems.tsRsltYmd = item.tsRsltYmd
                  // 현재보다 과거이거나, TS입력날짜가 오늘이면 신청취소 버튼 비활성화
                  if (lv_Vm.selectItems.tsAplYmd + lv_Vm.selectItems.tsAplTm + lv_Vm.selectItems.tsAplMm < lv_Vm.currentDate + lv_Vm.currentTime ||
                      lv_Vm.selectItems.tsEfYmd === lv_Vm.selectItems.choiceDate ||
                      lv_Vm.selectItems.tsRsltYmd === lv_Vm.selectItems.choiceDate ||
                      lv_Vm.$bizUtil.isEmpty(lv_Vm.selectItems.tsAplYmd) ||
                      lv_Vm.selectItems.choiceDate < lv_Vm.currentDate) {
                    lv_Vm.disableAply = true
                  } else {
                    lv_Vm.disableAply = false
                  }
                  // 오늘만 버튼 활성화 & TS입력날짜가 오늘이면 결과입력 버튼 비활성화
                  if (lv_Vm.selectItems.tsEfYmd === lv_Vm.currentDate ||
                      lv_Vm.selectItems.tsRsltYmd === lv_Vm.currentDate ||
                      lv_Vm.selectItems.choiceDate !== lv_Vm.currentDate) {
                    lv_Vm.disableRslt = true
                  } else {
                    lv_Vm.disableRslt = false
                  }
                  let userInfo = lv_Vm.getStore('userInfo').getters.getUserInfo
                  let R100 = ''
                  let F102 = ''
                  if (userInfo.userRoleList.length > 0) {
                    userInfo.userRoleList.map((t) => {
                      if (t === 'R100') {
                        R100 = t
                      } else if (t === 'F102') {
                        F102 = t
                      }
                    })
                  }
                  if (!_.isEmpty(R100) || !_.isEmpty(F102)) {
                    if (lv_Vm.selectItems.tsEfYmd === lv_Vm.currentDate ||
                        lv_Vm.selectItems.tsRsltYmd === lv_Vm.currentDate &&
                        lv_Vm.selectItems.choiceDate === lv_Vm.currentDate) {
                      // lv_Vm.getStore('confirm').dispatch('ADD', '이미 결과입력한 후보자입니다.\n(추가 입력은 신청부터 다시 진행하시면 됩니다)')
                      // lv_Vm.radio = '' // 라디오 초기화

                      // 기입력된 TS결과가 있어도 재입력이 가능하도록 버튼 활성화
                      lv_Vm.disableRslt = false
                    }
                    if (lv_Vm.selectItems.choiceDate !== lv_Vm.currentDate) {
                      lv_Vm.getStore('confirm').dispatch('ADD', '당일만 결과입력 가능합니다.')
                      lv_Vm.radio = '' // 라디오 초기화
                    }
                  }
                })
              }
            }
          }
        })
    },

    /******************************************************************************
    * Function명 : fn_CheckPreSelectedItem
    * 설명       : 이전에 등록한 정보로 선택한 후보자의 TS 결과 입력 날짜를 가져온다
    ******************************************************************************/
    fn_CheckPreSelectedItem (item) {            
      let lv_Vm = this
      // 버튼 초기화
      lv_Vm.disableAply = true
      lv_Vm.disableRslt = true

      // 선택값 저장
      lv_Vm.selectItems = item

      // 라디오 세팅
      lv_Vm.radio = item.name      
      const filterData = this.selCndtrList.filter(p => p.parentId === item.parentId)      
      for (let i = 0; i < filterData.length; i++) {
        filterData.forEach((item) => {          
          lv_Vm.selectItems.tsAplYmd = item.tsAplYmd
          lv_Vm.selectItems.tsEfYmd = item.tsEfYmd
          lv_Vm.selectItems.tsRsltYmd = item.tsRsltYmd
          // 현재보다 과거이거나, TS입력날짜가 오늘이면 신청취소 버튼 비활성화
          if (lv_Vm.selectItems.tsAplYmd + lv_Vm.selectItems.tsAplTm + lv_Vm.selectItems.tsAplMm < lv_Vm.currentDate + lv_Vm.currentTime ||
              lv_Vm.selectItems.tsEfYmd === lv_Vm.selectItems.choiceDate ||
              lv_Vm.selectItems.tsRsltYmd === lv_Vm.selectItems.choiceDate ||
              lv_Vm.$bizUtil.isEmpty(lv_Vm.selectItems.tsAplYmd) ||
              lv_Vm.selectItems.choiceDate < lv_Vm.currentDate) {
            lv_Vm.disableAply = true
          } else {
            lv_Vm.disableAply = false
          }
          // 오늘만 버튼 활성화 & TS입력날짜가 오늘이면 결과입력 버튼 비활성화
          if (lv_Vm.selectItems.tsEfYmd === lv_Vm.currentDate ||
              lv_Vm.selectItems.tsRsltYmd === lv_Vm.currentDate ||
              lv_Vm.selectItems.choiceDate !== lv_Vm.currentDate) {
            lv_Vm.disableRslt = true
          } else {
            lv_Vm.disableRslt = false
          }
          let userInfo = lv_Vm.getStore('userInfo').getters.getUserInfo
          let R100 = ''
          let F102 = ''
          if (userInfo.userRoleList.length > 0) {
            userInfo.userRoleList.map((t) => {
              if (t === 'R100') {
                R100 = t
              } else if (t === 'F102') {
                F102 = t
              }
            })
          }
          if (!_.isEmpty(R100) || !_.isEmpty(F102)) {            
            if (lv_Vm.selectItems.tsEfYmd === lv_Vm.currentDate ||
                lv_Vm.selectItems.tsRsltYmd === lv_Vm.currentDate &&
                lv_Vm.selectItems.choiceDate === lv_Vm.currentDate) {              
                lv_Vm.disableRslt = false
            }
            if (lv_Vm.selectItems.choiceDate !== lv_Vm.currentDate) {
              lv_Vm.getStore('confirm').dispatch('ADD', '당일만 결과입력 가능합니다.')
              lv_Vm.radio = '' // 라디오 초기화
            }
          }
        })        
      }
    },

    /******************************************************************************
    * Function명 : getTsCmpltRslt
    * 설명       : TS 완료 표시
    ******************************************************************************/
    getTsCmpltRslt (item) {
      let rtn = false
      if (item && this.selCndtrList && this.selCndtrList.length > 0) {
        rtn = this.selCndtrList.some(p => p.tsEfYmd && p.tsEfYmd.trim() !== '' && p.parentId === item.parentId)
      }
      return rtn
    },

    /******************************************************************************
     * Function명 : fn_ConfirmTsAply
     * 설명       : 신청 취소 확인 팝업
     ******************************************************************************/
    fn_ConfirmTsAply () {
      let contentMsg = moment(this.selectItems.tsAplYmd).format('YYYY-MM-DD') + ' 신청한 [' + this.selectItems.name + '] 후보자의<br> TS신청을 취소하시겠습니까?'

      this.alertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            //single: true,
            title: "",
            content: contentMsg,
            title_pos_btn: "확인",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.alertPop);

              // TS 취소
              this.fn_TsAplyCncr()
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.alertPop);
            }
          }
        })
    },

    /******************************************************************************
     * Function명 : fn_TsAplyCncr
     * 설명       : TS 취소
     ******************************************************************************/
    fn_TsAplyCncr () {
      let lv_Vm = this
      let userInfo = lv_Vm.getStore('userInfo').getters.getUserInfo
      let trnstId = 'txTSSRC60I1'
      let pParams = {
        stafEno: userInfo.cnsltNo, // 임직원사번
        hqOrgCd: lv_Vm.selectItems.hqOrgCd, // 본부조직코드
        dofOrgCd: lv_Vm.selectItems.dofOrgCd, // 지역단조직코드
        fofOrgCd: lv_Vm.selectItems.fofOrgCd, // 지점조직코드
        tsAplYmd: lv_Vm.selectItems.tsAplYmd, // TS신청일자
        tsAplTm: lv_Vm.selectItems.tsAplTm, // TS신청시간(1~24)
        tsAplMm: lv_Vm.selectItems.tsAplMm, // TS신청분(30분단위)
        parentId: lv_Vm.selectItems.parentId, // 후보자ID
        tsAplSc: '2' // TS신청구분(1:신청, 2:취소)
      }

      lv_Vm.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body != null) {
            let result = response.body
            // msgTyp(S : 성공, E : 에러)
            if (result.msgTyp === 'S') {
              // 팝업 닫고 캘린더 재조회
              lv_Vm.$emit('onPopupClose352')
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', result.msgTxt)
            }
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_CheckTSResult
     * 설명       : 기입력된 TS결과가 있는지 확인
     ******************************************************************************/
    fn_CheckTSResult () {      
      let userInfo = this.getStore('userInfo').getters.getUserInfo
      let R100 = ''
      let F102 = ''
      if (userInfo.userRoleList.length > 0) {
        userInfo.userRoleList.map((t) => {
          if (t === 'R100') {
            R100 = t
          } else if (t === 'F102') {
            F102 = t
          }
        })
      }
      if (!_.isEmpty(R100) || !_.isEmpty(F102)) {
        if (this.selectItems.tsEfYmd === this.currentDate ||
            this.selectItems.tsRsltYmd === this.currentDate &&
            this.selectItems.choiceDate === this.currentDate) {
          let contentMsg = '기존에 입력된 TS결과가 있습니다.<br>TS결과를 다시 입력하시겠습니까?'

          this.alertPop = this.$bottomModal.open(MSPBottomAlert, {
              properties: {
                //single: true,
                title: "",
                content: contentMsg,
                title_pos_btn: "결과입력",
                title_neg_btn: "취소"
              },
              listeners: {
                onPopupConfirm: () => {
                  this.$bottomModal.close(this.alertPop);

                  // 결과 입력
                  this.fn_BtnMSPRC353P()
                },
                onPopupClose: () => {
                  this.$bottomModal.close(this.alertPop);
                  this.radio = '' // 라디오 초기화
                  this.disableRslt = true // 버튼 비활성화
                }
              }
            })
        } else {
          // 결과 입력
          this.fn_BtnMSPRC353P()
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_BtnMSPRC353P
     * 설명       : 선택한 후보자의 TS 결과입력을 위한 화면으로 이동한다
     ******************************************************************************/
    fn_BtnMSPRC353P () {
      if (_.isEmpty(this.selectItems)) {
        this.getStore('confirm').dispatch('ADD', '후보자를 선택해주시기 바랍니다.')
      } else {
        let parentId = this.selectItems.parentId // 후보자ID
        let hqOrgCd = this.selectItems.hqOrgCd // 본부조직코드
        let dofOrgCd = this.selectItems.dofOrgCd // 지역단조직코드
        let fofOrgCd = this.selectItems.fofOrgCd // 지점조직코드
        let cndtrNm = this.selectItems.name // 후보자이름

        this.cndtrInfo = {
          parentId: parentId,
          hqOrgCd: hqOrgCd,
          dofOrgCd: dofOrgCd,
          fofCd: fofOrgCd,
          cndtrNm: cndtrNm
        }

        // 결과입력 팝업 호출
        this.popup353 = this.$bottomModal.open(MSPRC353P, {
          properties: {
            cndtrInfo: this.cndtrInfo
          },
          listeners: {
            onPopupClose353: () => {
              this.$bottomModal.close(this.popup353);

              // 결과 전송 완료 시 팝업 닫고 TS캘린더로 이동
              this.$emit('onPopupClose353')
            }
          }
        })
      }
    },

    /******************************************************************************
     * Function명 : fn_SetSelCtvtPnsta
     * 설명       : 배양현황 리스트 조회 및 설정
     ******************************************************************************/
    async fn_SetSelCtvtPnsta(pParentId) {
      // console.log('fn_SetSelCtvtPnsta', pParentId)
      const lv_Vm = this
      let pParams = {
        hqOrgCd: this.userInfo.onpstHofOrgNo, // 본부조직코드
        dofOrgCd: this.userInfo.onpstDofNo, // 지역단조직코드
        fofOrgCd: this.userInfo.onpstFofOrgNo, // 지점조직코드
        parentId: pParentId
      }
      let trnstId = 'txTSSRC40S1'
      let auth = 'S'
      await this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.whlCndtrList) && response.body.whlCndtrList.length > 0) {
            let result = response.body.whlCndtrList
            if (result.length !== 0) {
              for (let i = 0; i < result.length; i++) {
                result.forEach((item) => {
                  // console.log('whlCndtrList - item', item)
                  lv_Vm.selCndtrList.push(item)
                })
              }
            }
          }
        })
        .catch(function (error) {          
          window.vue.error(error)          
        })
    },

    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 취소 버튼
     ******************************************************************************/
    fn_Close () {
      this.$emit('onPopupCancel')
    },

    /******************************************************************************
     * Function명 : fn_ConvertDate
     * 설명       : 요일 변환
     ******************************************************************************/
    fn_ConvertDate (v) {
      switch (v) {
        case 'Mon':
          return '월'
        case 'Tue':
          return '화'
        case 'Wed':
          return '수'
        case 'Thu':
          return '목'
        case 'Fri':
          return '금'
        case 'Sat':
          return '토'
        case 'Sun':
          return '일'
      }
    },

    /******************************************************************************
     * Function명 : fn_ConvertAmPm
     * 설명       : am/pm 변환
     ******************************************************************************/
    fn_ConvertAmPm (data1) {
      let ret_val = ''

      if (data1 === null || data1.length !== 2) {
        ret_val = ''
      } else {
        if (Number(data1) < 12) {
          ret_val = 'am'
        } else if (Number(data1) > 12) {
          ret_val = 'pm'
        } else {
          ret_val = 'pm'
        }
      }
      return ret_val
    },

    /******************************************************************************
     * Function명 : fn_ConvertTime
     * 설명       : 오전/오후 변환
     ******************************************************************************/
    fn_ConvertTime (data1, data2) {
      let ret_val = ''

      if (data1 === null || data1.length !== 2 || data2 === null || data2.length !== 2) {
        ret_val = ''
      } else {
        if (Number(data1) < 12) {
          ret_val = '오전 ' + data1 + ':' + data2
        } else if (Number(data1) > 12) {
          if ((Number(data1) - 12) > 9) {
            ret_val = '오후 ' + (Number(data1) - 12) + ':' + data2
          } else {
            ret_val = '오후 ' + '0' + (Number(data1) - 12) + ':' + data2
          }
        } else {
          ret_val = '오후 ' + '12' + ':' + data2
        }
      }
      return ret_val
    }

  }
}
</script>
