/*
 * 업무구분: 리크루팅
 * 화 면 명: MSPCM351P
 * 화면설명: TS캘린더 상세조회
 * 작 성 일: 2022.06.30
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container title="상세조회" :show-title="false" type="popup">
    <ur-box-container direction="column" alignV="start" class="ns-sch-area">

      <!-- 조직 검색 컴포넌트 -->
      <SearchDetail ref="refSearchOrg" v-model="lv_SelectOrg" :isShowCnslt="false" :isFofType="1" :pOrgData="pOrgData351P" @selectResult="fn_SlectCnsltResultRC"/>

      <!-- 버튼 영역 -->
      <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Clear">초기화</mo-button>
          <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Search">조회</mo-button>
        </div>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import SearchDetail from '@/ui/rc/SearchDetail.vue'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM351P',
  // 현재 화면 ID
  screenId: 'MSPCM351P',
  // 컴포넌트 선언
  components: {
    SearchDetail
  },
  // 화면명
  props: {
    title: {
      type: String,
      default: '상세조회'
    },
    // MSPRC350M TS캘린더
    pOrgData350M: {
      type: Object,
      default: {}
    }
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      lv_SelectOrg: [], // 조직 컴포넌트 데이터
      lv_ReturnVal: {}, // 리턴값
      isShowCnslt: false, // 담당컨설턴트
      allChk: false, // 조직, 담당컨설턴트 조회완료 여부
      pOrgData351P: {},
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {},

  /** watch 정의 영역 */
  watch: {},

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPRC351P')

    this.pOrgData351P = this.pOrgData350M
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SlectCnsltResultRC()
     * 설명       : 조직 변경시, 전역변수에 셋팅
     *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
     * isShowCnslt: 조직, 담당컨설턴트까지 모두 조회후, 셋팅하였는지 여부
     * allChk: 조회버튼 활성화/비활성화 여부
     ******************************************************************************/
    fn_SlectCnsltResultRC(pData) {
      this.lv_SelectOrg = pData
      if (this.isShowCnslt) {
        this.allChk = true
      }
      this.isShowCnslt = true
    },

    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear() {
      this.pOrgData351P = {}
      this.$refs.refSearchOrg.fn_GetFofRol() // 초기화후, 다시 조회
    },

    /******************************************************************************
     * Function명 : fn_SetReturnVal
     * 설명       : 팝업 닫힐때, 최종 return 입력값 셋팅
     ******************************************************************************/
    fn_SetReturnVal() {
      if (_.isEmpty(this.lv_SelectOrg)) return

      this.lv_ReturnVal = {} // 초기화
      this.lv_ReturnVal.cnsltEno = !_.isEmpty(this.lv_SelectOrg.cnslt) ? this.lv_SelectOrg.cnslt.key : '' // 컨설턴트 사번
      this.lv_ReturnVal.dofOrgCd = !_.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.key : '' // 선택지역단
      this.lv_ReturnVal.fofOrgCd = !_.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.key : '' // 선택사업부
      this.lv_ReturnVal.hofOrgCd = !_.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.key : '' // 선택지점
      this.lv_ReturnVal.pOrgData = this.lv_SelectOrg // 조회 조직 데이터
    },

    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회 후 팝업 닫음
     ******************************************************************************/
    fn_Search() {
      this.fn_SetReturnVal() // 리턴값 세팅

      this.$emit('onPopupSearch', { OrgData: this.lv_ReturnVal });
    }
  }
}
</script>
