/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC354P
 * 화면 설명: TS 결과입력 - 인터뷰 결과
 * 작 성 일: 2022.06.30
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container :show-title="false" title="인터뷰 결과" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <!-- 인터뷰 결과 -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-interview-result">
          <!-- 답변내용 -->
          <div class="interview-result-area">
            <div class="interview-ttl">답변내용</div>
            <ul class="interview-list" v-for="(item, idx) in tsRsltSelInsVO.intviItmList1" :key="idx">
              <li class="interview-item">
                <div class="index">{{ `Q${idx + 1}.` }}</div><!-- 문항번호 -->

                <div class="interview-item1">
                  <span class="qus">{{ item.obtAnsw }}</span><!-- 문항 타이틀 -->
                  <span class="ans">{{ item.questCntnt }}</span><!-- 문항 답변내용 -->
                </div>
              </li>
            </ul>
          </div>
          <!-- 체크사항 -->
          <div class="interview-result-area">
            <div class="interview-ttl">체크사항</div>
            <ul class="interview-list" v-for="(item, idx) in tsRsltSelInsVO.intviItmList2" :key="idx">
              <li class="interview-item">
                <div class="index">{{ `Q${lenList1 + idx + 1}.` }}</div><!-- 문항번호 -->

                <div class="interview-item1">
                  <span class="qus">{{ item.obtAnsw }}</span><!-- 문항 타이틀 -->
                  <span class="ans">{{ item.questCntnt }}</span><!-- 문항 답변내용 -->
                </div>
              </li>
            </ul>
          </div>
          <!-- 배양정도 -->
          <div class="interview-result-area">
            <div class="interview-ttl">배양정도</div>
            <ul class="interview-list" v-for="(item, idx) in tsRsltSelInsVO.intviItmList3" :key="idx">
              <li class="interview-item">
                <div class="index">{{ `Q${lenList1 + lenList2 + idx + 1}.` }}</div><!-- 문항번호 -->

                <div class="interview-item1">
                  <span class="qus">{{ item.obtAnsw }}</span><!-- 문항 타이틀 -->
                  <span class="ans">{{ item.questCntnt }}</span><!-- 문항 답변내용 -->
                </div>
              </li>
            </ul>
          </div>
          <div class="interview-result-area">
            <div class="interview-ttl">평가자 의견 <span>{{`${tsRsltSelInsVO.recmFcNm}(${ intviRsltYmd })`}}</span></div>
            <ul class="interview-list">
              <li class="interview-item">
                <div class="interview-item1">
                  <span class="ans">{{ tsRsltSelInsVO.bmngrOpin }}</span>
                </div>
              </li>
            </ul>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  name: "MSPRC354P",
  screenId: "MSPRC354P",
  mixins:[Screen],
  components: {},
  props: {
    tsRsltSelInsVO: {
      type: Object
    },
    cndtrInfo: {
      type: Object
    }
  },

  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right'
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data() {
    return {
      intviRsltYmd: '', // 인터뷰 실시일

      lenList1: 0, // 답변내용 length
      lenList2: 0, // 체크사항 length
      lenList3: 0 // 배양정도 length
    }
  },
 
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created() {
    // 인터뷰 실시일
    this.intviRsltYmd = (!_.isEmpty(this.tsRsltSelInsVO.intviYmd) && this.tsRsltSelInsVO.intviYmd !== '00000000') ? moment(this.tsRsltSelInsVO.intviYmd).format('YYYY-MM-DD') : ''

    this.lenList1 = this.tsRsltSelInsVO.intviItmList1.length // 답변내용 length
    this.lenList2 = this.tsRsltSelInsVO.intviItmList2.length // 체크사항 length
    this.lenList3 = this.tsRsltSelInsVO.intviItmList3.length // 배양정도 length
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPRC354P')
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
  }
}
</script>