/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC353P
 * 화면 설명: TS 결과입력
 * 작 성 일: 2022.06.30
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container :show-title="false" title="TS결과입력" type="popup" @before-close="fn_ConfmModalOpen">
    <ur-box-container direction="column" alignV="start">

      <!-- 후보자 info -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-interview-list">
        <mo-list :list-data="tsResult">
          <template #list-item="{ item }">
            <div class="ns-list-button">
              <mo-list-item expandable ref="expItem">
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="theads">후보자</span>
                      <span class="txtSkip">{{cndtrInfo.cndtrNm}} {{ `${Number(item.cndtrAge)}세` }}</span>
                      <em>|</em>
                      <span>{{ item.dob === 'undefined' ? '' : $commonUtil.dateDashFormat(item.dob) }}</span>
                    </div>
                  </div>
                </div>
                <template v-slot:expand class="ns-gray-box">
                  <div class="list-item__contents">
                    <div class="list-item__contents__info">
                      <span class="txtSkip colors">{{ `${item.dofOrgNm} ${item.fofNm}` }}</span>
                    </div>
                    <div class="list-item__contents__info">
                      <span class="info">후보자 등록일</span>
                      <span class="info txtSkip">{{ item.cndtrRegYmd === 'undefined' ? '' :  $commonUtil.dateDashFormat(item.cndtrRegYmd) }}</span>
                    </div>
                    <div class="list-item__contents__info">
                      <span class="info">적격여부</span>
                      <span class="info txtSkip">{{ item.qlfYn }}</span>
                    </div>
                    <div class="list-item__contents__info">
                      <span class="info">속성구분</span>
                      <span class="info txtSkip">{{ item.attrScCd }}</span>
                    </div>
                    <div class="list-item__contents__info">
                      <span class="info">추천FC</span>
                      <span class="info txtSkip">{{`${item.recmFcNm} (${Number(item.recmFcEno)})`}}</span>
                    </div>
                  </div>
                </template>
              </mo-list-item>
            </div>
          </template>
        </mo-list>
      </ur-box-container>

      <!-- 인터뷰 결과 버튼 -->
      <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-round-btn">
        <mo-button class="ns-btn-round" @click="fn_BtnMSPRC354P">인터뷰 결과</mo-button>
      </ur-box-container>
      
      <!-- 질문지 -->
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-question-area">
          <div v-for="(item, index) in tsAnswList" :key="item.obtCd">
            <div v-if="index === currentQuestion">
              <!-- 항목이름 -->
              <div class="ttl">{{ fn_GetTitle(item.clsfItm) }}</div>
              <!-- 문항번호/문항타이틀 -->
              <ul class="interview-list">
                  <li class="interview-item">
                    <div class="index">Q{{ index + 1 }}.</div><div>{{ item.viewQuestCntnt }}</div>
                  </li>
              </ul>
              <!-- 라디오 선택 영역 -->
              <div class="ns-radio-list">
                <mo-radio-wrapper :items="answerData[index].choice" v-model="item.key" @input="fn_SelectEvent(item)"/>
              </div>
            </div>
          </div>

          <!-- 평가자 의견 -->
          <div style="width: 100%;" v-if="tsAnswList.length > 0 && currentQuestion === tsAnswList.length">
            <div class="ttl">평가자 의견</div>
            <ul class="interview-list">
                <li class="interview-item">
                  <div class="index">Q{{ tsAnswList.length + 1 }}.</div>
                  <div>아래 답변영역에 작성해주세요.</div>
                </li>
            </ul>
            <div class="flex-item txtarea" label="Count text field">
              <mo-text-area class="last-content" v-model="msgText" :maxlength="200" placeholder="간단 메모 200자 이내로 작성해주세요." counter="200" />
            </div>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_020" direction="column" class="ns-btn-relative-arrow">
          <div class="relative-div" v-if="tsAnswList.length > 0 && currentQuestion === tsAnswList.length">
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white befor"
                        v-show="currentQuestion > 0" @click="fn_Interview('pre')">이전</mo-button>
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round blue"
                        v-show="isSubmitBtn" @click="fn_ModalOpen">작성 완료</mo-button>
          </div>
          <div class="relative-div" v-else>
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white befor"
                        @click="fn_Interview('pre')" :style="currentQuestion<1? 'opacity: 0;': ''">이전</mo-button>
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round next"
                        v-show="isNextBtn" @click="fn_Interview('next')">다음</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>

    <!-- 제출/취소 Modal -->
    <div class="ns-bottom-sheet ns-bottom-popup-m">
      <mo-bottom-sheet ref="refConfirmModal353">
          <template v-slot:title></template>
          <p class="ns-seminar-txt">
            작성중인 화면에서 나가시겠습니까?<br/>
            나가기 선택 시 작성된 내용은 저장되지 않습니다.
          </p>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button class="ns-btn-round white" @click="fn_ConfmModalClose('N')">아니오</mo-button>
                <mo-button class="ns-btn-round blue" @click="closeFn">예</mo-button>
              </div>
            </ur-box-container>
          </template>
      </mo-bottom-sheet>
    </div>

  </ur-page-container>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Screen from '~systems/mixin/screen'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPRC354P from '@/ui/rc/MSPRC354P.vue' // TS 결과입력 - 인터뷰 결과

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  name: "MSPRC353P",
  screenId: "MSPRC353P",
  mixins:[Screen],
  components: {
    MSPRC354P
  },
  props: {
    cndtrInfo: {
      type: Object
    }
  },

  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right',
    beforeClose: true
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data() {
    return {
      // TS결과 조회입력VO
      tsRsltSelInsVO: {
        cndtrAge: '',
        dob: '',
        cndtrRegYmd: '',
        qlfYn: '',
        attrScCd: '',
        dofOrgNm: '',
        fofNm: '',
        recmFcNm: '',
        recmFcEno: '',
        intviItmList: []
      },
      tsResult: [], // 후보자 데이터
      tsItmList: [], // TS질문항목 List
      tsAnswList: [], // TS답변항목 List
      answerList: [], // 질문에 대한 답변 데이터 (key값만 갖고 있음)
      answerData: [], // 답변지 데이터
      currentQuestion: 0, // 현재 진행 문항 스텝 index (문항지 스텝별 진행)
      msgText: '', // 평가자 의견(input text)

      isNextBtn: false,    // 다음 버튼 show/hide 핸들링 변수
      isSubmitBtn: false,  // 작성완료 버튼 show/hide 핸들링 변수

      timerFn: '',
      isInterval: false, // 문항 답변지 선택시, setTimeOut 중복 호출 방지
      isSubmitFin: false, // 결과입력 완료여부

      closeFn: '', // 진행중단 확인

      popup354: {}
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** watch 정의 영역 */
  watch: {
    // 평가자 의견 입력
    msgText () {
      if (!this.$bizUtil.isEmpty(this.msgText) && this.msgText.length > 0) {
        this.isSubmitBtn = true
      } else {
        this.isSubmitBtn = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created() {
    if (!this.$bizUtil.isEmpty(this.cndtrInfo) && !this.$bizUtil.isEmpty(this.cndtrInfo.parentId)) {
      this.fn_prcsTsRslt('2')
    }
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPRC353P')
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_prcsTsRslt
     * 설명       : TS항목 조회 및 결과입력
     ******************************************************************************/
    fn_prcsTsRslt (type) {
      let lv_Vm = this
      let userInfo = lv_Vm.getStore('userInfo').getters.getUserInfo
      let pParams = {
        itmScCd: type, // 항목구분(1:결과조회, 2:항목조회, 3:입력)
        parentId: lv_Vm.cndtrInfo.parentId, // 후보자ID
        cnsltEno: userInfo.cnsltNo, // 컨설턴트사번
        hqOrgCd: lv_Vm.cndtrInfo.hqOrgCd, // 본부조직코드
        dofOrgCd: lv_Vm.cndtrInfo.dofOrgCd, // 지역단조직코드
        fofOrgCd: lv_Vm.cndtrInfo.fofCd // 지점조직코드
      }

      lv_Vm.isSubmitFin = false // 결과입력 완료 여부

      // 파라미터 세팅 구분(항목조회/결과입력)
      if (type === '2') {
        // 항목조회
        pParams.intviYmd = lv_Vm.cndtrInfo.intviRsltYmd // 인터뷰 일자
      } else if (type === '3') {
        // 결과입력
        pParams.tsEvalr = '' // TS평가자(20:영업파트장, 30:지역단장, 40:조직파트장)

        let tsItmList = []
        lv_Vm.tsAnswList.forEach((item, idx) => {
          if (item.clsfCd !== '0012') {
            let inObj = {
              prgSeq: item.prgSeq, // 진행순서
              clsfCd: item.clsfCd, // 분류코드
              clsfItm: item.clsfItm, // 분류항목
              obtAnsw: item.key, // 객관식답변
              obtAnswCntnt: item.answList.filter(answIdx => answIdx.answCd === item.key)[0].answCntnt, // 객관식답변내용
              obtCd: item.obtCd, // 질문코드
              questCntnt: item.questCntnt, // 질문내용
              addAnswCntnt: '' // 추가답변내용
            }

            tsItmList.push(inObj)
          } else {
            // 최종평가와 평가자의견은 기존 EP영업인사에서도 사용하고 있는 데이터라 0레벨 필드에 값 세팅해주어야함
            if (item.clsfCd === '0012') {
              // 최종평가
              pParams.tsLstEval = lv_Vm.tsAnswList[idx].key
            }
          }
        })
        // 평가자의견은 리스트에 없으므로 따로 세팅해줌
        pParams.evalrOpin = lv_Vm.msgText

        pParams.tsItmList = tsItmList
      }

      let trnstId = 'txTSSRC60P1'

      // 체크사항 - 원거리/출퇴근, 신용 불량은 SFP만 보이도록 해야함
      lv_Vm.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body != null) {
            let result = response.body

            lv_Vm.tsResult.push(result)

            if (type === '2') {
              // 항목조회
              lv_Vm.tsRsltSelInsVO = {} // 초기화
              lv_Vm.tsRsltSelInsVO = {...result}

              /*================================================================
              * >>> TS항목 LIST 데이터 구성
              * chnlScCd(11:FC, 12:SFP)
              * SFP는 체크사항의 부모님 동거(obtCd:007), 군필(obtCd:008) 보이도록하고, FC는 데이터 숨긴다.
              ==================================================================*/
              let titleArr = []
              if (result.tsItmList.length > 0) {
                // list 초기화
                lv_Vm.tsItmList = [] // TS질문항목 List
                lv_Vm.tsAnswList = [] // TS답변항목 List
                lv_Vm.answerList = []

                let tmpTsItmList = []

                // 분류별 타이틀 추출
                result.tsItmList.map(title => {
                  let tmpTitle = title.clsfItm.indexOf('|') > -1 ? title.clsfItm.substr(0, title.clsfItm.indexOf('|')) : title.clsfItm
                  if (!titleArr.includes(tmpTitle)) titleArr.push(tmpTitle)
                  // [2021-08-31] SFP외 나머지 채널구분 분기 처리
                  if (result.chnlScCd === '12') { // SFP
                    lv_Vm.answerList.push([])
                    tmpTsItmList.push(title)
                  } else { // 그 외 (FC, GFC 등..)
                    if (title.obtCd !== '0007' && title.obtCd !== '0008') {
                      lv_Vm.answerList.push([])
                      tmpTsItmList.push(title)
                    }
                  }
                })

                // 분류별 데이터로 질문지 구성
                let qstNo = 0
                titleArr.forEach((item, idx) => {
                  let tmpArr = tmpTsItmList.filter(item2 => {
                    return item2.clsfItm.includes(item)
                  })
                  let tmpObj = {}
                  tmpObj.title = item
                  if (tmpArr.length > 0) {
                    tmpArr.forEach(setItem => {
                      let clsfItmIdx = setItem.clsfItm.indexOf('|')
                      setItem.index = qstNo
                      if (clsfItmIdx > -1) {
                        setItem.viewQuestCntnt = `[${setItem.clsfItm.substr(clsfItmIdx + 1)}] ${setItem.questCntnt}` // 질문내용
                        setItem.answList = setItem.answList.reverse()
                      } else {
                        if (setItem.clsfCd === '0012') { // 최종평가
                          setItem.viewQuestCntnt = '아래 답변영역에서 선택해주세요.'
                        } else {
                          setItem.viewQuestCntnt = setItem.questCntnt
                        }
                      }
                      setItem.answList.forEach((answItem, answIdx) => {
                        answItem.index = answIdx
                        if (setItem.clsfCd === '0012' || setItem.clsfCd === '0001') { // 0012: 최종평가, 0001: 체크사항
                          answItem.answNo = ''
                        }
                      })
                      setItem.checkbox = false
                      qstNo++
                    })
                    tmpObj.data = tmpArr
                  } else {
                    tmpObj.data = []
                  }
                  lv_Vm.tsItmList.push(tmpObj)
                  lv_Vm.tsAnswList.push(...tmpArr)
                })
              }

              /*================================================================
              * >>> 답변지 LIST 데이터 구성
              ==================================================================*/
              let lv_answerObj = {} // 답변 데이터에 들어갈 object

              // 답변지 데이터 구성
              for (let i = 0; i < lv_Vm.tsAnswList.length; i++) {
                let lv_answerList = []
                lv_Vm.tsAnswList[i].answList.forEach((item) => {
                  lv_answerList.push(item)
                })

                // 답변 데이터에 들어갈 object
                lv_answerObj = {
                  key: i, // 답변 목록 INDEX
                  checkbox: false,
                  choice: [] // 답변보기
                }

                lv_answerList.forEach((item) => {
                  let lv_choiceObj = {
                    value: '', // 답변코드
                    key: '', // 답변번호
                    text: '', // 답변내용
                  }
                  lv_choiceObj.value = item.answCd // 답변코드
                  lv_choiceObj.key = item.answCd // 답변번호
                  lv_choiceObj.text = item.answCntnt // 답변내용
                  lv_choiceObj.text += item.answNo ? ' ' + item.answNo + '점' : '' // 점수추가
                  lv_answerObj.choice.push(lv_choiceObj)
                })

                lv_Vm.answerData.push(lv_answerObj) // 답변지 데이터
              }

              /*================================================================
              * >>> 인터뷰결과 LIST 데이터 구성
              ==================================================================*/
              if (result.intviItmList.length > 0) {
                lv_Vm.tsRsltSelInsVO.intviItmList1 = []
                lv_Vm.tsRsltSelInsVO.intviItmList2 = []
                lv_Vm.tsRsltSelInsVO.intviItmList3 = []
                result.intviItmList.forEach((intvItem, index) => {
                  let tempObj = {}
                  tempObj.obtAnsw = intvItem.obtAnsw // 질문텍스트
                  if (intvItem.obtCd === '0004') { // 현재 직업 또는 과거 경력(질문코드: 0004)
                    if (!lv_Vm.$bizUtil.isEmpty(intvItem.addAnswCntnt)) { // 추가 답변 내용 有
                      tempObj.questCntnt = `${intvItem.questCntnt}${intvItem.addAnswCntnt}` // 답변 텍스트
                    } else {
                      tempObj.questCntnt = intvItem.questCntnt // 답변 텍스트
                    }
                  } else {
                    tempObj.questCntnt = intvItem.questCntnt // 답변 텍스트
                  }
                  if (intvItem.clsfCd === '0006') { // 배양정도 (분류코드 : 0006)
                    if (intvItem.obtCd === '0018') { // 교육일정 안내(질문코드 : 0018)
                      tempObj.questCntnt = intvItem.questCntnt === '아니오' ? 'N' : 'Y'
                    }
                    lv_Vm.tsRsltSelInsVO.intviItmList3.push(tempObj)
                  } else if (intvItem.clsfCd === '0005') { // 체크리스트
                    tempObj.questCntnt = intvItem.questCntnt === '아니오' ? 'N' : 'Y'
                    lv_Vm.tsRsltSelInsVO.intviItmList2.push(tempObj)
                  } else {
                    lv_Vm.tsRsltSelInsVO.intviItmList1.push(tempObj) // 답변내용
                  }
                })
              }
            } else if (type === '3') {
              // 결과입력
              if (result.msgTyp === 'S') {
                lv_Vm.isSubmitFin = true // 결과입력 완료여부 true
                // 결과 전송 완료 시 팝업 닫고 TS캘린더로 이동
                lv_Vm.$emit('onPopupClose353')
              }
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_BtnMSPRC354P
     * 설명       : 인터뷰결과 팝업 호출
     ******************************************************************************/
    fn_BtnMSPRC354P () {
      // [2021-08-31] EP에서 입력한 경우 인터뷰리스트 존재하지 않음으로 안내 메시지 노출 처리
      if (this.tsRsltSelInsVO.intviItmList !== null && this.tsRsltSelInsVO.intviItmList.length < 1) {
        this.getStore('confirm').dispatch('ADD', '기존 영업인사 화면(EP)에서 입력한 인터뷰 입니다.\n EP영업인사 화면에서 확인하시길 바랍니다.')
      }

      this.popup354 = this.$bottomModal.open(MSPRC354P, {
        properties: {
          tsRsltSelInsVO: this.tsRsltSelInsVO,
          cndtrInfo: this.cndtrInfo
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.popup354);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.popup354);
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_GetTitle
     * 설명       : 타이틀 추출
     ******************************************************************************/
    fn_GetTitle (str) {
      let rtnVal = ''
      rtnVal = str.split('|')[0] // ex) 태도|이미지관리 > 태도

      return rtnVal
    },

    /******************************************************************************
     * Function명 : fn_Interview
     * 설명       : 이전 / 다음 버튼 클릭시 함수 실행
     *              > 이전 / 다음 스텝 문항으로 전환
     ******************************************************************************/
    fn_Interview(param, item) {
      this.fn_SetLabel(item)

      if (param === 'next' && this.currentQuestion < this.tsAnswList.length) {
        this.currentQuestion = this.currentQuestion + 1
        let nextItem = this.tsAnswList[this.currentQuestion]

        if (!this.$bizUtil.isEmpty(nextItem) && !this.$bizUtil.isEmpty(nextItem.key)) {
          this.isNextBtn = true
        } else {
          this.isNextBtn = false
        }
      } else if (param === 'pre' && this.currentQuestion > 0) {
        this.isNextBtn = true
        this.currentQuestion = this.currentQuestion - 1
      }

      if (this.currentQuestion === this.tsAnswList.length) return
    },

    /******************************************************************************
     * Function명 : fn_SetLabel
     * 설명       : 인터뷰 문항 답변 셋팅
     ******************************************************************************/
    fn_SetLabel(selectItem) {
      if (_.isEmpty(selectItem) || selectItem.answList.lnegth < 1)  return

      selectItem.answList.forEach((item) => {
        if (item.answCd === selectItem.intviValu) {
          selectItem.answCntnt = item.answCntnt
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_ModalOpen
     * 설명       : 작성완료 버튼 누를 시 Confirm 창
     ******************************************************************************/
    fn_ModalOpen() { 
      let contentMsg = 'TS결과입력 작성 완료하시겠습니까?'
      // let contentMsg = '제출 후 수정이 불가합니다.<br>제출하시겠습니까?'

      this.alertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            //single: true,
            title: "",
            content: contentMsg,
            title_pos_btn: "제출",
            title_neg_btn: "취소"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.alertPop);

              // 결과 입력
              this.fn_prcsTsRslt('3')
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.alertPop);
            }
          }
        })
    },

    /******************************************************************************
     * Function명 : fn_SelectEvent
     * 설명       : 선택시 0.7초 대기시간 추가
     ******************************************************************************/
    fn_SelectEvent (item) {
      const lv_Vm = this
      if (this.isInterval) return

      this.isInterval = true
      this.timerFn = setTimeout(function() {
        lv_Vm.fn_Interview('next', item)
        lv_Vm.isInterval = false
      }, '700') // 0.7초 뒤 다음 문항 (PM건의사항: 2022.07.20)
    },

    /******************************************************************************
     * Function명 : fn_ConfmModalOpen
     * 설명       : 닫기 팝업(진행중단)
     ******************************************************************************/
    fn_ConfmModalOpen (closeFn) {
      this.closeFn = closeFn

      // 결과입력 완료시 바텀시트 오픈하지 않음
      if (this.isSubmitFin === true) return

      this.$refs.refConfirmModal353.open()
    },

    /******************************************************************************
     * Function명 : fn_ConfmModalClose
     * 설명       : 닫기 팝업 취소(진행중단 취소)
     ******************************************************************************/
    fn_ConfmModalClose (param) {
      this.$refs.refConfirmModal353.close()
    }

  }
}
</script>